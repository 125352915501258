






























import { defineComponent } from "@vue/composition-api";
import validator from "validator";

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        name: "",
        website: "",
        photo: "",
        description: "",
      }),
    },
  },

  setup(_, { root }) {
    const rules = {
      website: [
        (v: string) =>
          !v ||
          validator.isURL(v, {
            protocols: ["http", "https"],
            require_protocol: true,
          }) ||
          root.$tc("layout.misc.validWebsiteAddress"),
      ],
    };
    return { rules };
  },
});
